.nav-bar {
  background: #181818;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px; // Add padding to the left of the navbar


  .logo {
    .logoName {
      width: auto;
      height: 25px; // Adjust the height to fit the navbar's height
    }
  }

  nav {
    display: flex;
    position: absolute;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;

    a {
      font-size: 20px; // Adjust based on your requirements
      color: #4d4d4e;
      display: flex; // Use flex for centering vertically
      align-items: center; // Center items vertically
      justify-content: center; // Center items horizontally
      width: 100px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.4s ease-out;
      }

      &:hover {
        color: #95a1f1;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: attr(data-text); // Show the text content defined in data-text attribute
        font-size: 14px;
        letter-spacing: 3px;
        position: absolute;
        bottom: 0;
        opacity: 0;
        transition: all 0.4s ease-out;
      }
    }
    a.active{
      svg{
        color: #F49E4C; // Perhaps change later, idk.
      }
    }
  }

  ul{
    position: absolute;
    display: flex;
    right: 20px;
    height: 35%;
    width: auto;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li{
      a{
        padding-right: 20px;
        font-size: 26px;

        &:hover svg{
          color: #F49E4C;
        }
      }
    }
  }
}

.hamburger,
.close-icon{
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-bar {
    background: transparent;
    position: initial;
    height: auto;
    width: auto;
    min-height: auto;

    ul, nav {
      display: none;
    }

    .logo{
      .logoName{
        width: auto;
        height: 19px;
        margin-top: 13px;
      }
    }

    nav {
      width: 101%;
      height: 100%;
      box-sizing: border-box;
      background: #181818;
      top: 0;
      left: 0;
      position: fixed;
      z-index: 2;
      margin: auto 50%;

      a{
        display: flex;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-left: 35%;
      }

      a:after{
        opacity: 1;
        position: initial;
        width: auto;
        margin-left: 10px;
      }

      a svg{
        opacity: 1 !important;
      }

      &.mobile-show{display: block;}
    }

    .hamburger,
    .close-icon{
      display: block;
      position: absolute;
      font-size: 25px;
      top: 10px;
      right: 10px
    }
  }
}




