.stage-cube-cont{
  width: 60%;
  height: 100%;
  top: 0;
  padding-top: 16%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;

  .cube-spinner {
    animation-name: spin;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 150px 150px 0;
    margin-left: calc(50% - 150px);

    div {
      position: absolute;
      width: 300px;
      height: 300px;
      border: 1px solid #000000;
      background: rgba(84, 81, 81, 0.5);
      text-align: center;
      font-size: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 10px 0 #783F8E;
    }

    .face1 {
      transform: translateZ(150px);
      color: #dd0031;
      background: rgba(9, 129, 74, 0.7);
    }
    .face2 {
      transform: rotateY(90deg) translateZ(150px);
      color: #f06529;
      background: rgba(244, 158, 76, 0.7);
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(150px);
      color: #28a4d9;
      background: rgba(13, 0, 164, 0.7);
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(150px);
      color: #5ed4f4;
      background: rgba(126, 126, 126, 0.7);
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(150px);
      color: #efd81d;
      background: rgba(255, 255, 255, 0.7);
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(150px);
      color: #FF934F;
      background: rgba(0, 0, 0, 0.7);
    }
  }
}

@keyframes spin {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}


@media screen and (max-width: 1200px){
  .container.about-page{
    h1.about-title {
      margin-left: 10px;
      margin-top: 0;
      font-size: 56px;
    }
  }

  .stage-cube-cont{
    position: initial;
    width: 100%;
    height: 0;
    margin-bottom: 305px;
    padding-bottom: 20px;
    overflow: visible;

    .cube-spinner {
      transform-origin: 100px 100px 0;
      margin-left: calc(50% - 100px);

      div {
        width: 200px;
        height: 200px;
        font-size: 150px;
      }

      .face1 {
        transform: translateZ(100px);
      }
      .face2 {
        transform: rotateY(90deg) translateZ(100px);
      }
      .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      }
      .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      }
      .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      }
      .face6 {
        transform: rotateX(-90deg) translateZ(100px);
      }
    }
  }
}