.contact-form{
  width: 100%;
  margin-top: 20px;

  ul{
    padding: 0;
    margin: 0;

    li{
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      overflow: hidden;
      display: block;
      position: relative;
      opacity: 0;
      animation:fadeInUp 1.5s 1.5s;
      animation-fill-mode: forwards;
      clear: both;
    }

    li.half{
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child{
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #a7b2f3;
    height: 60px;
    font-size: 28px;
    color: #000000;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea{
    width: 100%;
    border: 0;
    background: #a7b2f3;
    height: 50px;
    font-size: 28px;
    padding: 20px;
    color: #000000;
    min-height: 200px;
    box-sizing: border-box;
  }

  .flat-button{
    color: #000000;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: "sans-serif";
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #000000;
    margin-top: 10px;
    float: right;
    border-radius: 6px;
    animation: cubic-bezier 1s 1.8s backwards;
    white-space: nowrap;
    background: #F49E4C;

    &:hover{
      background: #AB3428;
      color: #333;
    }
  }
}

.info-map{
  position: absolute;
  background: #000000;
  top: 50px;
  right: 32%;
  z-index: 9999;
  width: 280px;
  padding: 20px;
  color: #fff;
  font-family: 'Helvetica', sans-serif;
  font-size: 25px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5 forwards;

  span {
    font-size: 24px;
    display: block;
    padding-top: 20px;
    color: #F49E4C;
  }
}

.map-wrap {
  background: rgba(79, 250, 225, 0.2);
  float: right;
  width: 52%;
  height: 100%;
  margin: 25px;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  animation: backInRight 1s 1.2s forwards;
}

@media screen and (max-width: 1200px){
  .map-wrap {
    float: none;
    width: 87%;
    height: 400px;
    justify-content: center;
    align-content: center;

  }

  .info-map{
    position: relative;
    top: 0;
    right: -16%;
    width: 240px;
    padding: 10px;
    font-size: 15px;

    span {
      font-size: 18px;
    }
  }
}
