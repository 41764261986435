.page{
  width: 100%;
  height: 100%;
  position: absolute;
}
.top-tags-html{
  margin: -100px;
}

.top-tags{
  bottom: auto;
  top: 80px;
}


.tags{
  color: #000000;
  opacity: .6;
  position: absolute;
  bottom: 0;
  left: 120px;
  font-size: 20px;
  font-family: 'La Belle Aurore', 'sans-serif';
}

.bottom-tags-html{
  margin: -100px;
}


.container{
  width: 100%;
  will-change: contents;
  height: 90%;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.about-page,
.contact-page,
.paintings-page{
  .text-zone {
    position: absolute;
    left: 10%;
    top: 55%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;

  }

  h1{
      font-size: 50px;
      font-family: 'Coolvetica', 'sans-serif';
      color: #181818;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 50px;
      left: 10px;

      &::before{
        content:'<h1>';
        color: black;
        opacity: 0.6;
        font-family: 'La Belle Aurore', 'sans-serif';
        font-size: 20px;
        position: absolute;
        margin-top: -25px;
        left: -10px;
        line-height: 35px;
      }

      &::after{
        content: '</h1>';
        font-family: 'La Belle Aurore', 'sans-serif';
        font-size: 20px;
        position: absolute;
        line-height: 35px;
        color: black;
        opacity: 0.6;
        bottom: -40px;
        left: -10px;
      }
    }

  p {
      font-size: 22px;
      color: #ffffff;
      font-family: 'sans-serif';
      font-weight:300;
      min-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.3s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.5s;
      }
    }

  .text-animate-hover{
    &:hover{
      color: #F49E4C;
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    position: initial;
  }

  .container{
    position: initial;
    height: auto;
    min-height: auto;


    &.about-page,
    &.contact-page,
    &.paintings-page{
      .text-zone {
        position: initial;
        transform: none;
        width: 100%;
        display: block;
        padding: 20px;
        box-sizing: border-box;

        h1 {
          font-size: 45px;
          padding-top: 20px;
        }

        p{
          font-size: 20px;
        }
      }
    }
  }
}