html{
  font-size: 62.5%;
}


body {
  margin: 0;
  font: 300 11px 'Helvetica Neue', 'sans-serif';
  color: #444;
  background: #8692e1; /*lavander=#95A1F1, aquamarine=#4dd5ca, */
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width:1200px) {
  body {
    overflow: visible;
  }
}