.paintings-page {
  padding-left: 100px;
  padding-right: 50px;
  width: calc(100% - 150px);
  position: initial;
  height: 100%;
  overflow: auto;

  h1.page-title {
    margin-left: 150px;
    margin-top: 200px;
    font-size: 86px;
  }

  .image-container {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    padding-bottom: 100px;
  }

  .image-box {
    position: relative;
    flex: 1 1 10%;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    max-width: calc(25% - 10px);

    .portfolio-image {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .content {
      position: absolute;
      width: 100%;
      z-index: 3;
      padding: 10px 20px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      background: linear-gradient(
                      180deg,
                      rgba(0, 0, 0, 0.2) 0,
                      rgba(0, 0, 0, 1)
      );
      bottom: -70px;
    }

    .title {
      margin-bottom: 0;
      margin-top: 0;
      color: #fff;
      font-size: 22px;
      font-weight: 500;
      line-height: 24px;
    }

    .description {
      font-size: 14px;
      margin-bottom: 5px;
      color: #fff;
      font-weight: 700;
    }

    .btn {
      margin-top: 20px;
      margin-bottom: 10px;
      padding: 0 15px;
      height: 40px;
      line-height: 34px;
      border: 2px solid #F49E4C;
      border-radius: 4px;
      font-size: 14px;
      color: #fff;
      background: transparent;
      text-transform: uppercase;
      font-weight: 700;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      cursor: pointer;
    }

    .btn:hover {
      transform: translateY(-3px);
      background: #F49E4C;
    }

    &:after {
      content: "";
      background: linear-gradient(180deg, #F49E4C, #000);
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
    }

    &:hover:after {
      opacity: 0.85;
    }

    &:hover .content {
      bottom: 0;
      background: transparent;
    }
  }


  .modal {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal img {
    /* Ensure the image is not too large */
    max-width: 80%;
    max-height: 80%;
    margin: auto;
    display: block;
  }
}

@media screen and (max-width: 1200px){
  .container.paintings-page {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;


    h1.page-title {
      margin-left: 10px;
      margin-top: 10px;
      font-size: 56px;
    }

    .image-container{
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .image-box{
      height: calc((100vw - 40px) / 3); // Adjust height to maintain square aspect ratio, accounting for padding
      max-width: calc(50% - 10px); // Adjust width for 3-column layout
      flex: 0 0 calc(50% - 10px); // Adjust flex-basis to match max-width and prevent growing
      margin-bottom: 10px; // Add some space below each row
    }
  }
}