.logo-container {
  z-index: 0;
  width: 600px;
  height: auto;
  position: absolute;
  top: 18%;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  opacity: 0;
  animation: fadeIn 1.5s 1s forwards;

  .solid-logo{
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 1; // set to 0 once you have the custom svg done
    animation: fadeIn 1s forwards;
  }
}

/*
.svg-container{
  stroke: #F49E4C;
  stroke-width: 5px;
}
 */
